import { Component, DestroyRef, inject, OnDestroy, OnInit } from '@angular/core';
import { PayoutMethod, Publisher, PublisherDetailView, Status } from '../../../../core/interfaces';
import { AdxadSidebarModalRef } from '../../../../ui/modules/sidebar-modal/sidebar-modal-ref';
import { AdxadModal } from '../../../../ui/modules/modal/modal.service';
import { AdxadSidebarModalConfig } from '../../../../ui/modules/sidebar-modal/sidebar-modal-config';
import { AlertsComponent } from '../../../../ui/components/alerts/alerts.component';
import { PublishersService } from '../../publishers.service';
import { PublisherFormComponent } from '../publisher-form/publisher-form.component';
import { RolesService } from '../../../../core/services/roles.service';
import { slideToggleAnimation } from '../../../../ui/animations';
import { MessageService } from '../../../../core/services/message.service';
import { QueryParamsService } from '../../../../core/services/query-params.service';
import { BreadcrumbsService } from '../../../../core/services/breadcrumbs.service';
import { AdxadSidebarModal } from '../../../../ui/modules/sidebar-modal/sidebar-modal.service';
import { APP_ROUTE } from '../../../../core/routes';
import { Router } from '@angular/router';
import { SpotViewComponent } from '../../../spots/components/spot-view/spot-view.component';
import { SpotFormComponent } from '../../../spots/components/spot-form/spot-form.component';
import { PayoutOrderFormComponent } from '../../../payouts/components/payout-order-form/payout-order-form.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-publisher-view',
  templateUrl: './publisher-view.component.html',
  styleUrls: ['./publisher-view.component.scss'],
  animations: [slideToggleAnimation]
})
export class PublisherViewComponent implements OnInit, OnDestroy {
  public isLoading = false;
  public isLoadingStatus = false;
  public publisher: Publisher;
  public publisherStatus = Status;
  public filtered = [];
  public isOpenSection = {
    accountManager: true,
    contacts: false,
    payment: false,
    taxStatus: false,
    address: false,
    dsps: true
  };

  private destroyRef = inject(DestroyRef);

  constructor(
    private modalRef: AdxadSidebarModalRef,
    private modal: AdxadModal,
    public config: AdxadSidebarModalConfig,
    private alerts: AlertsComponent,
    private publisherService: PublishersService,
    public roles: RolesService,
    private messageService: MessageService,
    private queryParamsService: QueryParamsService,
    public breadcrumbs: BreadcrumbsService,
    private sidebarModal: AdxadSidebarModal,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (!this.config || !this.config.data || !this.config.data.id) {
      this.alerts.error('Oops something went wrong', 3000);
      this.closeModal();
    }

    this.queryParamsService.add('sm_publisherView', this.config.data.id);

    this.messageService
      .get('reload-publisher-view')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: data => {
          if (data.submit) {
            this.loadPublisherDetails(data.value as string);
          }
        }
      });

    this.loadPublisherDetails();
  }

  /**
   * Load publisher data
   * @param id - publisher id
   */
  loadPublisherDetails(id?: string): void {
    if (this.isLoading) {
      return;
    }
    const publisherId = id ? id : this.config.data.id;
    this.isLoading = true;

    this.publisherService
      .getPublisherDetails({ id: publisherId })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (result: PublisherDetailView) => {
          this.isLoading = false;

          if (result.status === 'OK') {
            this.publisher = result.data;
            this.filtered = result.data.spots;
          }
        },
        error: () => this.closeModal()
      });
  }

  /**
   * Set active or blocked status
   *
   * @param status
   */
  changeStatus(status: string): void {
    if (status === undefined || this.isLoadingStatus) {
      return;
    }

    this.isLoadingStatus = true;

    const data = {
      ids: [this.config.data.id],
      status: status
    };

    this.publisherService
      .changePublishersStatus(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (result: any) => {
          this.isLoadingStatus = false;

          if (result.status === 'OK') {
            const message =
              'The publisher ' +
              this.publisher.email +
              ' ' +
              (this.publisher.status === this.publisherStatus.blocked ? this.publisherStatus.active : this.publisherStatus.blocked);
            this.publisher.status = result.data[0].status;
            this.messageService.add('reload-publishers-grid', { submit: true });
            this.alerts.success(message, 3000);
          }
        }
      });
  }

  /**
   * Open edit publisher form in modal
   */
  openEditForm(): void {
    this.modal
      .open(PublisherFormComponent, {
        width: '880px',
        data: {
          id: this.publisher.id,
          managerId: this.roles.isAdmin() ? this.publisher.manager.id : ''
        }
      })
      .afterClosed.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: value => {
          if (value && value.submit) {
            this.loadPublisherDetails();
          }
        }
      });
  }

  /**
   * Close campaign view component;
   * Open previous component
   */
  goBack(): void {
    this.closeModal();

    const back = this.breadcrumbs.backStep;
    if (!back) {
      return;
    }
    this.breadcrumbs.removeLastStep();

    this.sidebarModal.open(back.component, {
      data: {
        id: back.id
      }
    });
  }

  /**
   * Open filtered spots grid by tariff
   */
  openSpotsGrid() {
    if (!this.publisher.spots.length) {
      return;
    }

    this.router
      .navigate([APP_ROUTE.spots], {
        queryParams: {
          f_owner: '[{"id":"' + this.publisher.id + '","value":"' + this.publisher.email + '"}]'
        }
      })
      .then(() => this.closeModal());
  }

  /**
   * Open filtered statistics grid by spot id
   */
  openStatisticsGrid() {
    this.router
      .navigate([APP_ROUTE.statistic], {
        queryParams: {
          g_publisher: 1,
          f_publisher: '[{"id":"' + this.publisher.slug + '","value":"' + this.publisher.email + '"}]'
        }
      })
      .then(() => this.closeModal());
  }

  /**
   * Close tariff view modal
   * Open detail spot view
   *
   * @param {string} id of project
   */
  openSpotView(id: string): void {
    this.closeModal();

    this.breadcrumbs.add({
      component: PublisherViewComponent,
      id: this.config.data.id
    });

    this.sidebarModal.open(SpotViewComponent, {
      data: {
        id: id
      }
    });
  }

  /**
   * Open create spot form
   */
  openSpotForm(): void {
    this.modal
      .open(SpotFormComponent, {
        width: '880px',
        data: {
          user: this.publisher.id
        }
      })
      .afterClosed.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: value => {
          if (value && value.submit) {
            this.loadPublisherDetails();
          }
        }
      });
  }

  /**
   * Filter spots
   *
   * @param value
   */
  filter(value: string): void {
    this.filtered = this.publisher.spots.filter(x => x.name.indexOf(value) !== -1);
  }

  isEmptyAddress(): boolean {
    return !(
      this.publisher.profile.address &&
      this.publisher.profile.address.city &&
      this.publisher.profile.address.country &&
      this.publisher.profile.address.zip
    );
  }

  isEmptyContacts(): boolean {
    return !(this.publisher.profile.languages.length && this.publisher.profile.messengers.length && this.publisher.profile.phoneNumber);
  }

  /**
   * Return payment method name
   * @param paymentMethod
   */
  getPaymentMethod(paymentMethod: string): string {
    return PayoutMethod[paymentMethod];
  }

  /**
   * Open order payout form
   */
  orderPayout(): void {
    this.modal.open(PayoutOrderFormComponent, {
      width: '640px',
      data: {
        ...this.publisher,
        isManager: true
      }
    });
  }

  /**
   * Close modal
   */
  closeModal(): void {
    this.modalRef.close();
  }

  ngOnDestroy() {
    this.queryParamsService.remove('sm_publisherView');
  }
}
