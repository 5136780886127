<div class="key">
  @if (api().key) {
    {{ api().key }}
  } @else {
  <button type="button"
          class="new"
          (click)="generateKey()"
  >
    Generate api key
  </button>
}
</div>
@if (api().key) {
  <div class="actions">

    <button
      [stroked]="true"
      (click)="toggleStatus()"
      adxadIconButton
      adxadTooltip="Click to change status"
    >
      <span class="material-icons-outlined"
            [class.active]="api().status === true"
      >
        {{ api().status ? 'toggle_on' : 'toggle_off' }}
      </span>
    </button>
    <button
      (click)="generateKey()"
      [stroked]="true"
      adxadIconButton
      adxadTooltip="Refresh api key"
    >
      <span class="material-icons"> refresh </span>
    </button>

    <button
      (click)="copyValue(api().key)"
      [stroked]="true"
      adxadIconButton
      adxadTooltip="Copy api key"
    >
      <span class="material-icons --copy"> filter_none </span>
    </button>

  </div>
}
